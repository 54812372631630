require('waypoints/lib/noframework.waypoints.js');
require('headroom.js/dist/headroom.min.js');

import Headroom from "headroom.js/dist/headroom.min.js";
const header = document.querySelector(".c-header__wrapper");
var options = {
    offset: 39,
}

const headroom = new Headroom(header, options);
headroom.init();

let navToggle = document.querySelector('.js-nav-toggle');
let menuWrapper = document.querySelector('.c-nav--primary__wrapper');

navToggle.addEventListener('click', function() {
    if (navToggle.classList.contains('utl-is-active')) {
        navToggle.classList.remove('utl-is-active');
        menuWrapper.classList.remove('utl-is-active');
        document.body.classList.remove("utl-menu-open");
        this.setAttribute('aria-expanded', 'false');

    } else {
        navToggle.classList.add('utl-is-active');
        menuWrapper.classList.add('utl-is-active');
        document.body.classList.add("utl-menu-open");
        this.setAttribute('aria-expanded', 'true');
    }
});

const toggles = document.querySelectorAll('.js-toggle');
for (let a of toggles) {
    a.addEventListener('click', function(event) {

        if (a.classList.contains('utl-is-expanded')) {
            a.classList.remove('utl-is-expanded');
            a.setAttribute('aria-expanded', 'false');
            event.preventDefault();

        } else {
            a.classList.add('utl-is-expanded');
            a.setAttribute('aria-expanded', 'true');
            event.preventDefault();
        }
    });
}

var elements = document.getElementsByClassName('js-animate');

for (var i = 0; i < elements.length; i++) {
    var element = elements[i];
    element.className += ' js-animate--pending';
}

var continuousElements = document.getElementsByClassName('js-animate')
for (var i = 0; i < continuousElements.length; i++) {
    new Waypoint({
        element: continuousElements[i],
        handler: function() {
            this.element.classList.add("js-animate--start")
        },
        offset: '97%'
    })
}

var lazyLoadInstance = new LazyLoad({
    elements_selector: ".js-lazy"
});


// VIEWPORT CHECK
var isOutOfViewport = function(elem) {
    var bounding = elem.getBoundingClientRect();

    // Check if it's out of the viewport on each side
    var out = {};
    out.top = bounding.top < 0;
    out.left = bounding.left < 0;
    out.bottom = bounding.bottom > (window.innerHeight || document.documentElement.clientHeight);
    out.right = bounding.right > (window.innerWidth || document.documentElement.clientWidth);
    out.any = out.top || out.left || out.bottom || out.right;
    out.all = out.top && out.left && out.bottom && out.right;

    return out;

    // how to use:
    // var elem = document.querySelector('#some-element');
    // var isOut = isOutOfViewport(elem);
    // if (isOut.top) { 'Top is out of viewport' }
    // if isOut options top, bottom, left, right, any, all
};

//floorplan hover image
var accommodation = document.querySelector('.c-tease__wrapper--accommodation');

if (accommodation) {
    var teases = accommodation.querySelectorAll('.c-tease');

    for (var i = 1; i < teases.length + 1; i++) {
        const tease = accommodation.querySelector('.c-tease.utl-i-' + i);
        const image = accommodation.querySelector('#accommodation-floorplan-' + i);

        if (image) {
            tease.addEventListener('mouseenter', e => {
                const isOut = isOutOfViewport(image);

                if (isOut.right) {
                    image.classList.add('utl-position--right');
                }

                e.preventDefault();
            });

            tease.addEventListener('mousemove', e => {
                const x = e.clientX;
                const y = e.clientY;

                image.style.left = x - 50 + 'px';
                image.style.top = y + 25 + 'px';

                e.preventDefault();
            });

            tease.addEventListener('mouseleave', e => {
                image.classList.remove('utl-position--right');

                e.preventDefault();
            });
        }
    }
}

// pause activity video when hover stops
var activities = document.querySelector('.m-specialisedActivities');
// var videos = activities.querySelectorAll('.m-specialisedActivity__video');

if (activities) {
    var activity = activities.querySelectorAll('.m-specialisedActivity');

    for (var i = 1; i < activity.length; i++) {
        const tease = activities.querySelector('#activity-' + i);
        const video = tease.querySelector('.m-specialisedActivity__video');

        if (video) {
            const iframe = video.querySelector('iframe');
            const command = {
              "event": "command",
              "func": "pauseVideo"
            };

            tease.addEventListener('mouseenter', e => {
                const isOut = isOutOfViewport(iframe);

                if (isOut.right) {
                    video.classList.add('utl-position--right');
                }

                e.preventDefault();
            });

            tease.addEventListener('mouseleave', e => {
                iframe.contentWindow.postMessage(JSON.stringify(command), "*");
                video.classList.remove('utl-position--right');
                e.preventDefault();
            });
        }
    }
}

//form tabs
var tabs = document.getElementById('tabs');
var forms = document.querySelectorAll('.c-form__form');

if ( tabs && forms ) {
    var buttons = tabs.querySelectorAll('.c-form__tabs__button');

    for (let button of buttons) {
        button.addEventListener('click', function(event) {
            removeActive(buttons);
            removeActive(forms);

            let formId = button.getAttribute("data-name");
            let currentForm = document.getElementById(formId);

            this.classList.add('utl-is-active');
            currentForm.classList.add('utl-is-active');
        });
    }
}

var removeActive = function(elems) {
    for (let e of elems) {
        e.classList.remove('utl-is-active');
    }
}
